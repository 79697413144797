@keyframes floatUpFadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.iphoneFrame {
  position: relative;
  width: 60vw;
  background-color: rgb(236, 228, 228);
  border-radius: 30px;
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 2.5vw 1vw 0.5vw 1vw;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin: 5px;
  animation: floatUpFadeIn 1s ease-out both;
  aspect-ratio: 179/380;
  /* box-sizing: content-box; */


}

.iphoneFrame::before {
  content: '';
  position: absolute;
  width: 15vw;
  height: 3vw;
  background-color: rgb(0, 0, 0);
  border-radius: 20px;
  top: 2vw;
}

.iphoneFrame img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 25px;
}

/* Media query for smaller screens */

/* Media query for larger screens */
@media screen and (min-width: 575px) {
  .iphoneFrame {
    width: 358px;
    /* box-sizing: content-box; */
    height: auto;
    aspect-ratio: 179/380;
    

  }

  .iphoneFrame::before {
    width: 70px;
    height: 20px;
    top: 12px;
  }

  .imageContainer img {
  }
}

/* Media query for larger screens */
@media screen and (min-width: 768px) {
  .iphoneFrame {
    width: 358px;
    height: 760px;
    /* box-sizing: content-box; */
    padding: 20px 5px 1px 5px;
    /* aspect-ratio: 179/380; */
  }

  .iphoneFrame::before {
    width: 70px;
    height: 20px;
    top: 12px;
  }
}
