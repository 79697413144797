/* ProfilePage.css */
body {
    background-color: #ffffff;
    color: #333;
    margin: 0;
    padding: 20px;
    font-size: 16px;
} 

.profile-container {
    max-width: 600px;
    background: white;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    object-fit: cover; /* Changes here */
}


.profile-image-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.edit-text {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease;
    font-size: 14px;
    color: #333;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 4px 8px;
    border-radius: 25px;
}

.profile-image-container:hover .edit-text {
    opacity: 1;
}

.profile-info {
    margin-top: 20px;
    border-bottom: 1px solid #eaeaea; /* Adds a light line as a divider */
    padding-bottom: 20px; /* Adds space above the divider */
    margin-bottom: 20px; /* Adds space below the divider */
}

.profile-info p {
    margin: 10px 0;
}

.profile-info strong {
    color: #333;
}

.button {
    background-color: #007aff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #005ecb;
}

.edit-name input,
.edit-handle input {
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 14px;
    text-align: center;
    outline: none;
    transition: border-color 0.3s;
}

.edit-name input:focus,
.edit-handle input:focus {
    border-color: #000000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.edit-name button,
.edit-handle button {
    margin-right: 5px;
    padding: 8px 12px;
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 25px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.edit-name button:hover,
.edit-handle button:hover {
    background-color: #0056b3;
}

.edit-name button:last-child,
.edit-handle button:last-child {
    background-color: #ccc;
    color: #333;
}

.edit-name button:last-child:hover,
.edit-handle button:last-child:hover {
    background-color: #999;
}

.name-container,
.handle-container {
    margin-bottom: 10px;
}

.edit-name,
.edit-handle {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.button-container {
    display: flex;
    justify-content: center;
}

.button-container button {
    margin: 0 5px;
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-container button:hover {
    background-color: #0056b3;
}

.button-container button:last-child {
    background-color: #ccc;
    color: #333;
}

.button-container button:last-child:hover {
    background-color: #999;
}

.events-container {
    margin-top: 20px;
}

.create-event-button {
    position: relative;
    padding: 5px 10px;
    background: linear-gradient(150deg, rgba(255, 184, 0, 1) 0%, rgba(255, 123, 172, 1) 80%);
    color: #ffffff;
    border: none;
    border-radius: 25px;
    font-size: 25px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 10;
    margin: 0 auto; /* Add this line to center the button horizontally */
    display: block; /* Add this line to make the button a block-level element */
    width: fit-content; /* Add this line to adjust the width of the button to its content */
}

.create-event-button:hover {
    background-color: #f2f2f2;
}

.create-event-button-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.profile-container {
    background: white;
    border-radius: 10px;
    margin-bottom: 30px; /* Adds space between the profile container and the events container */
    padding: 20px;
    text-align: center;
}

/* ... (existing styles) ... */

.profile-event-type-toggle {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
}

.profile-toggle-button {
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    font-size: 16px;
    color: #657786;
    cursor: pointer;
    transition: color 0.3s;
    z-index: 1;
}

.profile-toggle-button.active {
    color: #1da1f2;
}

.profile-toggle-slider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 4px;
    background-color: #1da1f2;
    transition: transform 0.3s;
}

.profile-toggle-slider.slide-right {
    transform: translateX(100%);
}

.profile-events-container {
    transition: transform 0.3s;
}

.profile-slide-in {
    transform: translateX(0);
}

.profile-slide-out {
    transform: translateX(-100%);
}

/* ProfilePage.css */
h1 {
    /* Your existing styles */
    margin-top: 0;
}

h2 { /* For the Events heading */
    font-size: 24px; /* Larger than other text */
    margin-top: 30px; /* Adds space above the heading */
}



.load-more-button {
    padding: 10px 20px;
    background-color: #007bff; /* Example color */
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 20px 0;
    display: block; /* To ensure it doesn't inline */
    /* Add any additional styling as needed */
}

.event-list-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.event-list-button {
    background-color: #f0f0f0;
    border: 2px solid transparent;
    color: #333;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    flex: 1;
    outline: none;
}

.event-list-button:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Shadow for better visibility */

}

.event-list-button:last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Shadow for better visibility */

}

.event-list-button.active {
    background-color: transparent;
    color: #000000;
}




@media (max-width: 600px) {
    .profile-container {
        padding: 10px;
    }

    .profile-info {
        margin-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .profile-image {
        width: 60px;
        height: 60px;
    }

    .edit-text {
        font-size: 12px;
    }

    .profile-info p {
        margin: 5px 0;
    }

    .edit-name input,
    .edit-handle input {
        font-size: 12px;
    }

    .edit-name button,
    .edit-handle button {
        font-size: 12px;
        padding: 6px 10px;
    }

    .button-container button {
        font-size: 12px;
        padding: 6px 10px;
    }

    .event-list-button {
        font-size: 14px;
        padding: 8px 16px;
    }

    .events-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .event-card {
        width: 100%;
        max-width: 400px;
        margin-bottom: 20px;
    }
}
