#about {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: linear-gradient(108deg, #f6d365, #f23ba9); */
    background-color: white;
}

#about .paragraphContainer {
    margin-top: 2rem;
    padding: 1rem;
    max-width: 800px;
}

.about-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-evenly;
}

#about .roundedRectangle {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    margin-top: 2rem;
    padding: 1rem 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
    #about .roundedRectangle {
        margin-top: 1rem;
    }
}

#about .roundedRectangle p {
    font-size: 1rem;
    line-height: 1.8;
    word-spacing: 0.15rem;
    text-align: left;
    hyphens: auto;
    color: #333;
}

@media (max-width: 480px) {
    #about .roundedRectangle p {
        font-size: 0.9rem;
    }
}

#about .roundedRectangle li {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.8;
    word-spacing: 0.15rem;
    text-align: left;
    hyphens: auto;
    color: #333;
}

@media (max-width: 480px) {
    #about .roundedRectangle li {
        font-size: 0.9rem;
        margin-bottom: 0.1rem;
    }
}

.roundedRectangle ul {
    padding-inline-start: 30px;
    padding-inline-end: 30px;
}

.about-container .names {
    color: #f33ba9;
    font-size: 1.5em;
    text-align: center;
    margin: 3em 0;
}

.about-container .names p {
    margin: 3em 0;
}

@media (max-width: 480px) {
    .about-container .names {
        font-size: 1em;
    }
}

@media screen and (max-width: 768px) {
    #about {
        background-color: white;
    }

    #about .roundedRectangle {
        margin-top: 0;
        padding: 1rem;
    }
}
