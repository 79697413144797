.explore-events-button {
    padding: 1px 10px;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 30px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    background-color: #ffffff;
    border: none;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease;
    transition: transform 0.3s ease-in-out;

}

.explore-events-button:hover {
    background-color: #ffffff;
    transform: scale(1.1);

}