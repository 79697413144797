nav {
    background-color: #ffffff;
    padding: 10px;
    position: relative;
  }
  
  .navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .hamburger-menu {
    cursor: pointer;
    position: absolute;
    left: 10px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 18px;
  }
  
  .bar {
    width: 100%;
    height: 2px;
    background-color: #000000;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .hamburger-menu.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(-45deg);
  }
  
  .hamburger-menu.open .bar:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger-menu.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(45deg);
  }
  
  .logo {
    text-align: center;
  }
  
  .logo img {
    height: 90px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 10px;
    background-color: #fffefe;
    min-width: 160px;
    width: 200px;
    box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1001; /* Increase the z-index value */
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
    border-radius: 25px;
}
  
  .dropdown-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-menu li {
    margin-bottom: 10px;
  }
  
  .dropdown-menu a {
    color: #333;
    text-decoration: none;
  }


.dropdown-menu ul li button {
  color: #f44336;

}

  
  .show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }