/* City Picker Button Styling */
.city-picker-button {
    background-color: #fff;
    border-radius: 25px; /* Same as date picker for consistency */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Same as date picker for consistency */
    margin-left: 20px; /* Adjust spacing as needed */
    padding: 5px 20px; /* Same padding as date picker for consistency */
    font-size: 1.2rem;
    color: #333;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    /* Add other properties like margin-right if you want more space around the button */
}
  
 /* City Picker Dropdown Styling */
/* Remove the rule that sets display: none on .city-picker-dropdown */
  
/* Rest of the .city-picker-dropdown styles remain unchanged */
.city-picker-dropdown {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(202, 9, 9, 0.1);
    width: auto;
    margin-top: 10px;
    padding: 10px;
    box-sizing: border-box;
    z-index: 10001;
    position: fixed; /* Changed from absolute to fixed */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust position to account for the element's own size */
    width: 80%; /* Set a width or max-width for the dropdown */
    max-width: 400px; /* Maximum width can be set as needed */
    box-sizing: border-box; /* Ensures padding doesn't add to the width */

    /* Add a max-height and overflow for scroll on smaller screens */
    max-height: 80%;
    overflow-y: auto;
}


  
  .city-picker-dropdown input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  .city-picker-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .city-picker-dropdown li {
    padding: 10px;
    cursor: pointer;
  }
  
  .city-picker-dropdown li:hover {
    background-color: #f0f0f0;
  }

  /* Styles to apply when the city picker is visible */
.city-picker-visible .city-picker-dropdown {
    display: block; /* Show the dropdown */
  }
  

/* Container for both the date picker and city picker buttons */
.selectors-container {
    display: flex;
    justify-content: space-between; /* This will place the two elements on opposite ends */
    align-items: center; /* Aligns items vertically */
    padding: 10px; /* Add padding as needed */
    gap: 20px; /* Space between children */
  }

  /* Similar to .calendar-backdrop, but for the city picker */
  .city-picker-backdrop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
    transition: opacity 0.3s ease;
    opacity: 1; /* Make visible */
    pointer-events: auto; /* Enable clicks on the backdrop */
    z-index: 9998; /* Slightly lower than the date picker backdrop for layering */
}

/* Make sure the city picker container has a higher z-index if it's a separate element */
.city-picker-container {
    z-index: 9999; /* Higher than the backdrop */
}

  
  /* Container for the city picker, similar to .datepicker-container */
  .city-picker-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 10000; /* Above the backdrop */
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: scale(0.95);
  }
  
  /* Show the backdrop and city picker container when active */
  .city-picker-visible .backdrop,
  .city-picker-visible .city-picker-container {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
  }
  
  /* Initially hidden */
  .city-picker-container {
    display: none;
  }
  
  /* Show the container when the city picker is visible */
  .city-picker-visible .city-picker-container {
    display: block;
  }
  
  
  

  @media screen and (max-width: 480px) {

    .city-picker-button {

      font-size: 1.0rem;

      /* Add other properties like margin-right if you want more space around the button */
  }

  }