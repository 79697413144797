.event-options-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    }
    
    .event-options-modal {
    background-color: white;
    padding: 20px;
    border-radius: 25px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    
    .event-options-modal-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    }
    
    .event-options-modal-content {
    display: flex;
    flex-direction: column;
    }
    
    .event-options-modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
    }
    
    .edit-button {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    }
    
    .delete-button {
    background-color: #ffffff;
    color: rgb(235, 9, 9);
    }
    
    .event-options-modal-message {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    }
    
    .event-options-modal-close {
    padding: 8px 16px;
    border: none;
    border-radius: 25px;
    font-size: 14px;
    background-color: #f0f0f0;
    cursor: pointer;
    margin: 0 auto;
    display: block;
    }
    
    .delete-confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    color: white;
    }
    
    .delete-confirmation-content {
    background-color: #333;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }
    
    .delete-confirmation-message {
    font-size: 18px;
    margin-bottom: 20px;
    }
    
    .delete-confirmation-buttons {
    display: flex;
    justify-content: flex-end;
    }
    
    .delete-confirmation-button {
    padding: 10px 20px;
    margin-left: 10px;
    font-size: 16px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    }
    
    .delete-confirmation-button.confirm-button {
    background-color: #dc3545;
    color: #fff;
    }
    
    .delete-confirmation-button.confirm-button:hover {
    background-color: #c82333;
    }
    
    .delete-confirmation-button.cancel-button {
    background-color: #6c757d;
    color: #fff;
    }
    
    .delete-confirmation-button.cancel-button:hover {
    background-color: #5a6268;
    }