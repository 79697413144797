* {
  box-sizing: border-box;
}

@keyframes floatUpFadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

#root {
  width: 100%;
  min-height: 100%;
}

#home {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'SFProRoundedBold', sans-serif;
}

.imageGallery {
  margin-top: 2rem;
  width: 100%;
}

.imageContainer {
  width: 100%;
  border-radius: 50px;
  object-fit: cover;
}

.paragraphContainer {
  margin-top: 0.5rem;
  padding: 1rem;
  max-width: 800px;
}

.roundedRectangle {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(174, 139, 139, 0.1);
}

.roundedRectangle p {
  font-size: 1.2rem;
  line-height: 1.8;
  word-spacing: 0.15rem;
  text-align: left;
  hyphens: auto;
  color: #333;
}

.iphoneFramesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.iphone14frame {
  height: 70vh;
  width: calc(70vh * 71.5 / 147.5);
  margin: 20px;
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.25)); 
  animation-name: floatUpFadeIn;
  animation-duration: 1s; /* Adjust the duration as needed */
  animation-fill-mode: forwards; /* Makes the element stay at the final state of the animation */
  overflow-anchor: none; 
}

/* Media query for larger screens */
@media screen and (min-width: 1200px) {
  .imageContainer {
    width: 48vw;
  }

  .paragraphContainer {
    max-width: 1074px !important;
    width: 1074px !important;
  }

  .roundedRectangle {
    max-width: 1074px;
  }
}

/* Mobile devices: display in vertical format */
@media screen and (max-width: 768px) {
  .roundedRectangle {
    padding: 0.75rem 1rem 0.75rem 1rem;
  }
  .roundedRectangle p {
    font-size: 1rem;
    line-height: 1.6;
    word-spacing: 0.1rem;
    hyphens: auto;
  }

  .imageGallery {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1rem;
  }

  .appStoreImageContainer {
    height: 100%;
  }

  .imageContainer {
    width: 90vw;
  }

  .iphoneFramesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }


}

@media screen and (max-width: 390px) {
  .imageContainer {
    width: 100%;
  }

  .iphoneFrame {
    width: 358px;
    padding: 20px 5px 1px 5px;
    height: 760px;
  }

  .iphoneFramesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .imageGallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }
}


.appStoreImageContainer {
  width: 100%;
  position: relative;
}

.appStoreImageContainer a {
  display: flex; /* Added to center the button horizontally */
  justify-content: center; /* Added to center the button horizontally */
  align-items: center; /* Added to center the button vertically */
  width: 100%; /* Added to make the container take full width */
  margin: auto;
  /*padding: 1rem;  Added some padding around the button */
}

.buttonImage {
  width: auto;
  height: auto; /* Updated to maintain the aspect ratio */
  max-width: 40%; /* Updated max-width */
  flex-grow: 1;
  margin-top: 1rem;
}

/* Large screens */
@media screen and (min-width: 1024px) {
  .buttonImage {
    max-width: 25%; /* Adjust max-width based on the device */
  }
}

/* Medium screens */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .buttonImage {
    max-width: 50%; /* Adjust max-width based on the device */
  }
}

/* Small screens */
@media screen and (max-width: 767px) {
  .buttonImage {
    max-width: 70%; /* Adjust max-width based on the device */
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
}

/* Extra small screens */
@media screen and (max-width: 480px) {
  .buttonImage {
    max-width: 55%; /* Adjust max-width based on the device */
  }
}


/* Inserting this collapsed row between two flex items will make 
 * the flex item that comes after it break to a new row */
.break {
  flex-basis: 100%;
}