.event-landing-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.event-landing-header {
  position: relative;
  margin-bottom: 20px;
}

.event-landing-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 25px;
  padding: 10px;
  position: relative;
  z-index: 1;
}

.event-header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.event-title {
  font-size: 32px;
  margin-bottom: 10px;
}

.event-location-category {
  font-size: 16px;
  margin-bottom: 5px;
  text-align: left;
}

.event-location-category span {
  display: inline-block;
}

.event-date {
  font-size: 16px;
  color: #000000;
  margin-bottom: 10px;
}

.event-host {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.event-host-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.event-options-button {
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 20px;
}

.host-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.host-name {
  font-size: 18px;
}

.event-details {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.event-description-landingPage {
  position: relative;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  padding: 20px;
  white-space: pre-wrap;
}

.event-description-landingPage p {
  line-height: 1.5;
  font-size: 16px;
  color: #333;
}

.show-more-button {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: #000000;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.event-location {
  margin-bottom: 20px;
}

.event-link {
  margin-bottom: 20px;
}

.event-link a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ffffff;
  color: #000000;
  text-decoration: none;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.add-to-calendar {
  margin-top: 20px;
}

.loading-wheel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 24px;
  color: #888;
}

.event-landing-attending-users {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  height: 40px;
  z-index: 2;
}

.event-landing-attending-user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: -4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.event-location p {
  margin-bottom: 20px;
  margin-top: 25px;
}

.event-host-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.event-host {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.copy-event-button {
  position: relative;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-right: 15px;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;

}

.copy-event-button:hover {
  background-color: #f0f0f0;
}

.copy-event-button .text-container {
  position: relative;
  display: inline-block;
}

.copy-event-button .original,
.copy-event-button .copied {
  transition: opacity 0.3s ease;
}

.copy-event-button .copied {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.copy-event-button.clicked .original {
  opacity: 0;
}

.copy-event-button.clicked .copied {
  opacity: 1;
}


.event-options-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 10px;

}

.event-rsvp-button,
.event-attending-button {
    display: block;
    width: 35%;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: center; /* This centers the text inside the button */
    color: #fff;
    background: linear-gradient(150deg, rgba(255, 184, 0, 1) 0%, rgba(255, 123, 172, 1) 80%);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 20px;
    margin-left: auto; /* Add this line */
    margin-right: auto; /* And this line */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

}


.event-attending-button {
    background-color: #28a745;
    cursor: default;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

.login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.attending-users-list {
  margin-top: 20px;
}

.attending-users-list h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.attending-users-count {
  font-size: 16px;
  color: #888;
}

.attending-user {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.attending-user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.attending-user-name {
  font-size: 16px;
}

.confetti-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .event-landing-page {
      padding: 10px;
  }
  .event-header-content {
      padding: 18px;
  }
  .event-title {
      font-size: 20px;
  }
  .event-date {
      font-size: 14px;
  }
  .host-image {
      width: 35px;
      height: 35px;
  }
  .host-name {
      font-size: 10px;
  }
  .event-details {
      padding: 10px;
      font-size: 14px;
  }
  .event-link a {
      padding: 8px 16px;
      font-size: 14px;
  }

  .copy-event-button {
    font-size: 11px;
    padding: 5px 11px;
    margin-right: 10px;
  }

  .event-options-button {
    font-size: 12px;
    width: 30px;
    height: 30px;
    margin-bottom: 11px;
  }

  .event-rsvp-button,
.event-attending-button {
    display: block;
    width: 48%;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: center; /* This centers the text inside the button */
    color: #fff;
    background: linear-gradient(150deg, rgba(255, 184, 0, 1) 0%, rgba(255, 123, 172, 1) 80%);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto; /* Add this line */
    margin-right: auto; /* And this line */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

}
}