.event-explore-page {
    max-width: 600px;
    background: white;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.page-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 1.5rem;
    padding: 1em;
}

.selected-date-display {
    display: inline-block;
    margin: 5px 0;
    padding: 5px 20px;
    background-color: #FFF;
    border-radius: 25px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 1.2rem;
    color: #333;
    cursor: pointer;
    user-select: none;
    border: none;
    outline: none;
}

.page-title button {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    padding: 0;
    cursor: pointer;
}

.selectors-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.datepicker-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transform: scale(0.95);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 999;
}

.calendar-backdrop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    transition: opacity 0.3s ease;
    opacity: 0;
    pointer-events: none;
    z-index: 998;
}

.calendar-visible .datepicker-container,
.calendar-visible .calendar-backdrop {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
}



@media screen and (max-width: 480px) {

    .selected-date-display {
        display: inline-block;
        margin: 5px 0;
        padding: 5px 20px;
        background-color: #FFF;
        border-radius: 25px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        font-size: 1.0rem;
        color: #333;
        cursor: pointer;
        user-select: none;
        border: none;
        outline: none;
    }

  }




