.event-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-card {
    width: 100%;
    max-width: 600px;
    background: #fff;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 20px;
    transition: transform 0.3s ease-in-out;
}

.event-card:hover {
    transform: scale(1.03);
}

.event-card-top {
    padding: 15px;
    align-items: left;
    display: flex;
    flex-direction: column;
}

.event-card-profile-section {
    display: flex;
    align-items: center;
    margin-bottom: -30px;
}

.event-card-host-info {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.event-card-category {
    font-size: 1rem;
    margin-left: auto;
}

.event-card-profile-image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}

.event-card-user-name {
    font-size: 1rem;
    color: #000000;
    padding-left: 10px;
    margin-bottom: 0;
}

.event-card-info {
    margin-top: 10px;
    text-align: left;
}

.event-card-title {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 4px;
}

.event-card-date {
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 8px;
}

.event-card-description {
    font-size: 0.9rem;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 5px 0;
}

.event-card-image-container {
    position: relative;
}

.event-card-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    border-radius: 25px;
    padding: 10px;
}

.event-card-attending-users-container {
    display: flex;
    position: relative;
    height: 40px;
    margin: 10px 1;
}

.event-card-attending-user-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
}

@media (max-width: 767px) {
    .event-card {
        width: 340px;
    }

    .event-card-top {
        padding: 12px;
    }

    .event-card-title {
        font-size: 1.1rem;
    }

    .event-card-description {
        font-size: 0.8rem;
    }

    .event-card-attending-user-image {
        width: 35px;
        height: 35px;
    }
}