.create-event-page-ce {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
}

.event-form-ce {
    display: flex;
    flex-direction: column;
}

.event-location-select-ce {
    width: 300px;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 20px;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.event-title-container-ce {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.event-title-label-ce {
    font-size: 25px;
    margin-bottom: 10px;
}

.event-title-input-ce {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    font-size: 16px;
    border-radius: 25px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.event-title-container-ce,
.event-category-container-ce {
    display: flex;
    justify-content: center;
}

.event-title-input-ce,
.event-category-select-ce,
.event-description-textarea-ce,
.event-url-input-ce {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 20px;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    text-align: center;
}

.event-title-input-ce,
.event-category-select-ce {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.event-description-textarea-ce {
    height: 200px;
    resize: vertical;
}

.date-picker-container-ce {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}


.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.react-datepicker__input-container input {
    text-align: center;
    width: 100%;
    border-radius: 25px;
    border: none;
}

.date-picker-label-ce {
    text-align: center;
    margin-bottom: 10px;
}

.location-toggle-container-ce {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.location-toggle-container-ce label {
    margin-right: 10px;
}

.tooltip-ce {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip-ce .tooltiptext-ce {
    visibility: hidden;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-ce:hover .tooltiptext-ce {
    visibility: visible;
    opacity: 1;
}

.event-url-container-ce,
.event-address-container-ce {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    align-items: center;
}

.event-url-input-ce,
.location-search-input-ce {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    font-size: 16px;
    border-radius: 25px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.switch-ce {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch-ce input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider-ce {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider-ce:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider-ce {
    background-color: #0cb60f;
}

input:focus + .slider-ce {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider-ce:before {
    transform: translateX(26px);
}

.event-details-section-ce {
    margin-top: 30px;
}

.event-details-title-ce {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.photo-input-container-ce {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.photo-input-label-ce {
    display: inline-block;
    padding: 12px 40px;
    border-radius: 25px;
    cursor: pointer;
    background: linear-gradient(150deg, rgba(255, 184, 0, 1) 0%, rgba(255, 123, 172, 1) 80%);
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.photo-preview-container-ce {
    position: relative;
    display: inline-block;
    margin-top: 10px;
}

.photo-preview-ce {
    max-width: 100%;
    max-height: 800px;
    border-radius: 25px;
}

.photo-delete-btn-ce {
    position: absolute;
    top: -12px;
    right: -9px;
    width: 30px;
    height: 30px;
    background-color: #ff0000;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    font-size: 17px;
    cursor: pointer;
}

.submit-btn-ce {
    padding: 5px 60px;
    font-size: 27px;
    max-width: 300px;
    color: white;
    background: linear-gradient(150deg, rgba(255, 184, 0, 1) 0%, rgba(255, 123, 172, 1) 80%);
    border-radius: 25px;
    margin-top: 20px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.loading-wheel {
    border: 2px solid #fff;
    border-top: 2px solid #000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.autocomplete-dropdown-container-ce {
    position: relative;
    margin-bottom: 0;
    min-width: 300px;
}

.location-search-input-ce {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.autocomplete-dropdown-ce {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
}

.suggestion-item-ce {
    padding: 10px;
    cursor: pointer;
}

.suggestion-item--active-ce {
    background-color: #f0f0f0;
}

/* Hover Effects */
.event-title-input-ce:hover,
.event-category-select-ce:hover,
.event-location-select-ce:hover,
.event-description-textarea-ce:hover,
.event-url-input-ce:hover,
.location-search-input-ce:hover,
.photo-input-label-ce:hover,
.submit-btn-ce:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
}

.photo-preview-container-ce:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
}

.event-category-select-ce,
.event-location-select-ce {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='%23000'%3E%3Cpath d='M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    padding-right: 30px;
    text-align-last: center;
}






@media (max-width: 600px) {
    .create-event-page-ce {
        padding: 10px;
    }

    .event-title-input-ce,
    .event-category-select-ce,
    .event-location-select-ce,
    .event-description-textarea-ce,
    .event-url-input-ce {
        font-size: 16px;
    }

    .event-description-textarea-ce {
        height: 150px;
    }

    .photo-preview-ce {
        max-height: 100%;
    }

    .event-location-select-ce,
    .event-category-select-ce {
        position: relative;
        z-index: 0;
    }

    .event-location-select-ce option,
    .event-category-select-ce option {
        position: auto;
        left: 0;
        right: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        border-top: none;
        padding: 10px;
        z-index: 2;
    }
}