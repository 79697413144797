.create-event-button {
    position: relative;
    padding: 5px 10px;
    background: linear-gradient(150deg, rgba(255, 184, 0, 1) 0%, rgba(255, 123, 172, 1) 80%);
    color: #ffffff;
    border: none;
    border-radius: 25px;
    font-size: 25px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 10;
    margin: 0 auto; /* Add this line to center the button horizontally */
    display: block; /* Add this line to make the button a block-level element */
    width: fit-content; /* Add this line to adjust the width of the button to its content */
    margin-bottom: 8px;
    transition: transform 0.3s ease-in-out;

}

.create-event-button:hover {
    background-color: #f2f2f2;
    transform: scale(1.1);

}



.event-list-toggle {
    margin-top: 10px; /* Add margin to the top of the list toggle */
}