

.date-dial-container {
    overflow-x: auto;
    overflow-y: hidden; /* Prevent vertical scrolling */
    white-space: nowrap;
    justify-content: flex-start; /* Align items to the start */
    max-width: 600px; /* Add this line to limit the width */
    padding: 20px 20px; /* Adds space inside the container, 10px top and bottom, 20px left and right */
    align-items: center;
  }
  
  .date-dial-item {
    display: inline-flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
    position: relative;
    cursor: pointer;
    user-select: none;
    transition: transform 0.3s ease, background-color 0.3s ease;
}



  .date-number {
    color: #757575; /* Gray color */
    font-size: .75em; /* Adjust as needed */
    margin-bottom: 8px; /* Increase space between the number and the day */
    z-index: 1; /* Above the circle */
  }

  .event-indicator {
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 6px;
    height: 6px;
    background-color: #64e568;
    border-radius: 50%;
    z-index: 1;
  }
  
  .date-day {
    font-size: .85em; /* Adjust as needed */
    color: #757575; /* Default gray color, will change when selected */
    z-index: 1; /* Above the circle */
  }
  
  .date-dial-item.selected::before {
    content: '';
    position: absolute;
    top: 79%;
    left: 50%;
    width: 42px; /* Circle size */
    height: 42px; /* Circle size */
    background: radial-gradient(circle at top left, #ffe56f 28%, #FD109F 100%); /* Gradient background */
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 0; /* Below the text */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4); /* Horizontal offset, vertical offset, blur radius, color */
  }
  
  .date-dial-item.selected .date-day {
    color: #FFFFFF; /* White color when selected */
    transform: scale(1.1); /* Slightly scale up the selected date for a pop effect */
  }
  
  .date-dial-item:not(.selected) .date-day {
    color: #757575; /* Gray color when not selected */
  }
  
  
  /* Hide scrollbar for Chrome, Safari and Opera */
.date-dial-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge, and Firefox */
  .date-dial-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .date-dial-item::before,
.date-dial-item .date-day {
  /* existing styles */
  transition: background 0.3s ease, color 0.3s ease; /* Animate the background and color changes */
}

@media screen and (max-width: 480px) {

  .date-dial-container {
    overflow-x: auto;
    overflow-y: hidden; /* Prevent vertical scrolling */
    white-space: nowrap;
    padding: 20px 20px; /* Adds space inside the container, 10px top and bottom, 20px left and right */
    justify-content: flex-start; /* Align items to the start */
    max-width: 600px; /* Add this line to limit the width */
  }

}