.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    z-index: 1000;
    width: 100%; /* Reduced width for a more compact layout */
    max-width: 350px; /* Reduced max width for desktop */
}

.form-input[type="date"] {
    padding: 12px 10px; /* Adjust padding as necessary */
    height: auto; /* Ensure the input height is not constrained */
    cursor: pointer; /* Explicitly show pointer cursor to indicate clickability */
}


.phone-input,
.verification-input,
.form-input {
    padding: 10px;
    margin: 10px 0;
    border-radius: 25px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    width: calc(100% - 20px); /* Adjusted width to make elements narrower */
    text-align: center;
}

.date-input-container {
    padding: 10px;
    margin: 10px 0;
    border-radius: 25px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    width: calc(100% - 20px); /* Adjusted width to make elements narrower */
    text-align: center;
}



.phone-input:focus,
.verification-input:focus,
.form-input:focus {
    border-color: #007bff;
}

.send-code-button,
.verify-code-button,
.form-submit-button {
    padding: 10px 20px;
    border: none;
    background-color: #ffffff;
    color: #000000;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    transition: background-color 0.3s;
    width: calc(100% - 20px); /* Ensures buttons are narrower */
    margin-top: 10px;
}

.send-code-button:hover,
.verify-code-button:hover,
.form-submit-button:hover {
    background-color: #f7f7f7;
}

#recaptcha-container {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1001;
}

.phone-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
    margin-right: 35px;
}

.phone-input-container,
.verification-code-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.phone-number-input {
    width: 10px;
    height: 40px;
    font-size: 15px;
    text-align: center;
    border: none;
    border-bottom: 2px solid #ccc;
    outline: none;
    transition: border-color 0.3s;
}

.phone-number-input:focus {
    border-color: #007bff;
}

.country-code {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.country-flag {
    font-size: 24px;
    margin-right: 5px;
}

.input-label {
    display: block;
    text-align: center; /* Center the text */
    width: 100%; /* Ensure it spans the full width of its container */
    margin-top: 10px;
    margin-bottom: 5px;
    
}


.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #46494a; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;

    
  }

  .verification-code-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.verification-code-input {
    width: 40px;
    height: 50px;
    font-size: 24px;
    text-align: center;
    border: none;
    border-bottom: 2px solid #ccc;
    outline: none;
    transition: border-color 0.3s;
}

.verification-code-input:focus {
    border-color: #007bff;
}

  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

@media (min-width: 768px) {
    .modal-content {
        max-width: 350px; /* Further reduced for a more uniform and compact design on desktop */
    }

    .phone-input,
    .verification-input,
    .form-input,
    .send-code-button,
    .verify-code-button,
    .form-submit-button,
    .date {
        width: calc(100% - 20px); /* Uniformly narrower elements */
        margin: 10px 0;
    }
}
